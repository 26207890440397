.myDaos {
  max-width: 1480px;
  margin: 15px auto 100px;
  background: rgba(0, 6, 13, 0.2);
  border: 1px solid rgba(black, 0.1);
  border-radius: 12px;
  padding: 45px 30px;
  width: 100%;
  text-align: center;

  h2 {
    font-size: 32px;
    line-height: 1;
    color: white;
    margin-bottom: 45px;
  }
  h3 {
    font-size: 16px;
    line-height: 1;
    color: white;
    margin-bottom: 25px;
  }
}
